import React from "react";
import { Link } from "components";
import { Box } from "@mui/material";
// import { graphql, useStaticQuery } from "gatsby";
// import { useColorMode } from "hooks";

function ServiceIcon({ service, title }) {
  const serviceIcons = {
    steam: "https://static.tinaynox.com/images/services/steam.png",
    itch: "https://static.tinaynox.com/images/services/itch.png",
    github: "https://static.tinaynox.com/images/services/github.png",
    youtube: "https://static.tinaynox.com/images/services/youtube.png",
    androidApk: "https://static.tinaynox.com/images/services/android_apk.png",
    googleDrive: "https://static.tinaynox.com/images/services/google_drive.png",
  };

  return (
    <Box
      as="span"
      sx={{
        minWidth: "2.5rem",
        minHeight: "2.5rem",
        maxWidth: "2.5rem",
        maxHeight: "2.5rem",
        borderRadius: "0.25rem",
        overflow: "hidden",
      }}
    >
      <img
        src={serviceIcons[service]}
        alt={title}
        style={{ maxWidth: "inherit", maxHeight: "inherit" }}
        title={title}
      />
    </Box>
  );
}

export default function ServiceLink({ uri, service, title }) {
  // TODO: set useColorMode()
  // const colorMode = useColorMode();
  // console.log("colorMode >>", colorMode);

  if (uri.includes("https://")) {
    // External link
    return (
      <a
        href={`${uri}/`}
        target="_blank"
        rel="noopener noreferrer"
        style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
      >
        <ServiceIcon service={service} title={title} />
      </a>
    );
  }
  return (
    <Link
      to={`${uri}/`}
      rel="noopener noreferrer"
      style={{ textDecoration: "underline", whiteSpace: "nowrap" }}
    >
      <ServiceIcon service={service} title={title} />
    </Link>
  );
}
