import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Box } from "@mui/material";

export default function embeddedAsset(node, richTextImages) {
  const image_id = node.data.target.sys.id;
  const imageData = richTextImages[image_id];

  if (imageData) {
    // Check it it's GIF
    const gifImages = imageData.image.images.sources.filter(
      (img) => img.type === "image/gif"
    );
    if (gifImages.length) {
      const { sizes, srcSet } = gifImages[0];
      return (
        <Box
          sx={{
            width: "inherit",
            maxWidth: "100%",
            // minHeight: "40vh",
          }}
        >
          <picture>
            <source srcset={srcSet} sizes={sizes} type="image/gif" />
            <img
              src={srcSet}
              alt={imageData.alt}
              style={{
                width: "auto",
                maxWidth: "100%",
                height: "100%",
                margin: "0.25rem 0 1rem",
                borderRadius: "0.25rem",
                overflow: "hidden",
              }}
            />
          </picture>
        </Box>
      );
    }

    // Else render image
    const image = getImage(imageData.image);
    if (image) {
      return (
        <GatsbyImage
          image={image}
          alt={imageData.alt}
          style={{ margin: "0.25rem 0 1rem", borderRadius: "0.25rem" }}
        />
      );
    }
  }

  return <></>;
}
